<template>
    <div>
        <b-icon icon="stopwatch" font-scale="1" ></b-icon>
        <p class="ml-1">{{timeLeft}} / {{maxPresent}}</p>
       
    </div>
</template>
<script>
export default {
    props:{
        increment:{
            type: Boolean,
            default: false
        },
        maxValue:{
            type: Number,
            default: 0,
        },
        // minQuestion:{
        //     type: Number,
        //     default: 1,
        //     required:true
        // }
    },
   
    data(){
        return{
            selectedTime: 0,
            timeLeft: '00:00',
            startTime:0,
            incrementBase: this.increment,
            maxPresent:'',
        }
    },
    methods:{
        setTime(seconds,currntTime) {
            if(seconds == 0 | currntTime){
                 this.incrementBase = true
            } 
            else this.incrementBase = false
            this.startTime = seconds
            clearInterval(intervalTimer);
            this.timer(seconds);
        },
        timer(seconds) {
            const now = Date.now();
            const end = now + seconds * 1000;
            this.displayTimeLeft(seconds);

            this.selectedTime = seconds;
            // this.initialTime = seconds;
            // this.displayEndTime(end);
            this.countdown(end);
        },
        countdown(end) {
      // this.initialTime = this.selectedTime;
        intervalTimer = setInterval(() => {
            //////console.log(end)
            var secondsLeft = 0;
            //////console.log((end - Date.now())/1000)
            if(!this.incrementBase)
            {
                secondsLeft = Math.round((end - Date.now()) / 1000);
                this.selectedTime -= 1
                if(secondsLeft < 0) {
                    // need to call parent event
                    this.$parent.timerEnd()
                    clearInterval(intervalTimer);
                    return;
                }
            }
            else
            {
                secondsLeft = Math.round((Date.now()-end) / 1000);
                this.selectedTime += 1
                if(secondsLeft == this.maxValue & secondsLeft !=0) {
                    // need to call parent event
                    this.$parent.timerEnd()
                    clearInterval(intervalTimer);
                    return;
                }
            }
            // if(secondsLeft === 0) {
            // this.endTime = 0;
            // }
            this.displayTimeLeft(secondsLeft)
        }, 1000);
        },
        displayTimeLeft(secondsLeft) {
            const minutes = Math.floor((secondsLeft % 3600) / 60);
            //////console.log(minutes)
            const seconds = secondsLeft % 60;
            //////console.log(seconds)
            this.timeLeft = `${zeroPadded(minutes)}:${zeroPadded(seconds)}`;
        },
        pauseTime(){
            clearInterval(intervalTimer);
        },
        resumeTime(){
            this.setTime(this.selectedTime)
        },
        restartTime(){
            this.setTime(this.startTime)
        },
    }
}

var intervalTimer;
function zeroPadded(num) {
  // 4 --> 04
  return num < 10 ? `0${num}` : num;
}

// function hourConvert(hour) {
//   // 15 --> 3
//   return (hour % 12) || 12;
// }
</script>