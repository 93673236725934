<template>
    <div>
        <div class="audio-player">
            <!-- <div class="track-title">Facts</div> -->
            <div id="seekObjContainer">
               <p class="text-right" > <small class="start-time">00:00</small> / 
                <small class="end-time">00:00</small>
                </p>
                <div id="timeline1">
                    <div id="seekObj1" class="playhead" style="margin-left: 0px;"></div>
                </div>
            </div>
            <div class="player-controls scrubber">
                <!-- <small class="start-time"></small> -->
            <!-- <div class="play-pause">play</div> -->
                <!-- <small class="end-time"></small> -->
            </div>
            <div class="audio-wrapper">
                <audio id="player2" @timeupdate="timeCal()" @ended="completeAudio()">
                <source :src="src" type="audio/mp3">
                </audio>
            </div>
        </div>
    </div>
</template>
<script>
import $ from "jquery";
export default {
    props:{
        recordSrc:{
            default:null,
            required:true
        },
    },
    data(){
        return{
            src:this.recordSrc,
            isPlaying:false,
            player1:null,
            onplayhead:null,
            playerId:null,
            timeline:null,
            playhead:null,
            timelineWidth:null
        }
    },
    methods:{
    audioPlay() {
        /*var player = document.getElementById("player2");*/
        var player = $("#player2")[0];
    //alert(player);
    player.load()
   
        
        player.play();
        this.initProgressBar();
        this.isPlaying = true;
            },
    stopPlayer(){
        this.player1.pause();
        this.isPlaying = false;
         //$(".play-pause").empty().text("PLAY");
    },
    completeAudio(){
        ////debugger
        this.$parent.recordStop()
        var progressbar = document.getElementById("seekObj1");
            progressbar.style.marginLeft = "0px";
    },
    initProgressBar() {
            //$(".play-pause").empty().text("PAUSE");
                this.player1 = document.getElementById("player2");
                this.player1.play();
                this.isPlaying = true;
        }, 
            timeCal() {
                var width = $("#timeline1").width();
                var length = document.getElementById("player2").duration;
                var current_time = document.getElementById("player2").currentTime;

                // calculate total length of value
                var totalLength = this.calculateTotalValue(length);
               ////console.log(totalLength);
                $(".end-time").html(totalLength);

                // calculate current value time
                var currentTime = this.calculateCurrentValue(current_time);
                $(".start-time").html(currentTime);
                ////console.log(currentTime)

                var progressbar = document.getElementById("seekObj1");
                progressbar.style.marginLeft = (width * (this.player1.currentTime / this.player1.duration))-15 + "px";

            },
            calculateTotalValue(length) {
                var minutes = Math.floor(length / 60);
                var  seconds_int = length - minutes * 60;
            if(seconds_int < 10){
                //console.info("here");
                seconds_int = "0"+seconds_int;
                //console.info(seconds_int);
            }
                var seconds_str = seconds_int.toString();
                var  seconds = seconds_str.substr(0, 2);
                    var time = minutes + ':' + seconds;
            //console.info(seconds_int)
                return time;
            },
            calculateCurrentValue(currentTime) {
               // var current_hour = parseInt(currentTime / 3600) % 24,
                    var current_minute = parseInt(currentTime / 60) % 60
                    var current_seconds_long = currentTime % 60
                    var current_seconds = current_seconds_long.toFixed()
                    var current_time = (current_minute < 10 ? "0" + current_minute : current_minute) + ":" + (current_seconds < 10 ? "0" + current_seconds : current_seconds);
                return current_time;
            },
            ballSeek() {
                this.onplayhead = null;
                this.playerId = null;
                this.timeline = document.getElementById("timeline1");
                this.playhead = document.getElementById("seekObj1");
                this.timelineWidth = this.timeline.offsetWidth - this.playhead.offsetWidth;

                this.timeline.addEventListener("click", this.seek);
                this.playhead.addEventListener('mousedown', this.drag);
                window.addEventListener('mouseup', this.mouseUp);

            },
            seek(event) {
                var player = document.getElementById("player2");
                player.currentTime = player.duration * this.clickPercent(event, this.timeline, this.timelineWidth);
            },
            clickPercent(e, timeline, timelineWidth) {
                return (event.clientX - this.getPosition(timeline)) / timelineWidth;
            },
            getPosition(el) {
                return el.getBoundingClientRect().left;
            },
            drag() {
                this.player1.removeEventListener("timeupdate", this.timeCal());
                this.onplayhead = $(this).attr("id");
                this.playerId = $(this).parents("li").find("audio").attr("id");
                var player = document.getElementById(this.playerId);
                window.addEventListener('mousemove', this.dragFunc);
                player.removeEventListener('timeupdate', this.timeUpdate());
            },
            dragFunc(e) {
                //var player = document.getElementById(this.onplayhead);
                var newMargLeft = e.clientX - this.getPosition(this.timeline);

                if (newMargLeft >= 0 && newMargLeft <= this.timelineWidth) {
                    this.playhead.style.marginLeft = newMargLeft + "px";
                }
                if (newMargLeft < 0) {
                    this.playhead.style.marginLeft = "0px";
                }
                if (newMargLeft > this.timelineWidth) {
                    this.playhead.style.marginLeft = this.timelineWidth + "px";
                }
            },
            mouseUp(e) {
                if (this.onplayhead != null) {
                    var player = document.getElementById(this.playerId);
                    window.removeEventListener('mousemove', this.dragFunc);
                    player.currentTime = player.duration * this.clickPercent(e, this.timeline, this.timelineWidth);
                    this.player1.addEventListener("timeupdate", this.timeCal);
                    player.addEventListener('timeupdate', this.timeUpdate);
                }
                this.onplayhead = null;
            },
            timeUpdate() {
                var player2 = document.getElementById(this.onplayhead);
                var player = document.getElementById(this.playerId);
                var playPercent = this.timelineWidth * (player.currentTime / player.duration);
                player2.style.marginLeft = playPercent + "px";
                // If song is over
                if (player.currentTime == player.duration) {
                    player.pause();
                }

            }
    },
}
</script>
<style scoped>
div#timeline1 {
    width: 99%;
    background: #ccc;
    display: block;
    border-radius: 10px;
}

div#seekObj1 {
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 50%;
}
</style>