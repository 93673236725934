<template>
    <div class="row">
    <div class="col-12 extrapadding text-center">
        
    <div class="row player">
         <div class="col-12 text-center mt-4">
            <div class="fixedWidth microRecordDiv m-2 text-center pl-3 pr-3">
        <h6><strong> Listen Audio</strong></h6>
        <p class="text-left mt-1" style="font-weight:600;">Current Status:</p>
        <p class="text-left mt-1 mb-2 placeInline" >{{recordMessage}} <app-Timer name="before" ref="Timer" :increment="false"/> <span v-if="status=='before'">seconds</span></p>
        <div class="audio-player">
            <VueSlideBar v-model="value"
            class="player"
            :min="0"
            :max="max"
            :processStyle="slider.processStyle"
            :lineHeight="slider.lineHeight"
            :isDisabled="slider.disabled"
            :draggable="!slider.disabled"
            :tooltipStyles="{ backgroundColor: 'white', borderColor: 'white' }"
            />
            <div class="audio-wrapper">
                <audio id="player" @playing="audioPlay()" @ended="stop()">
                <source :src="src" type="audio/mp3">
                </audio>
            </div>
        </div>
        </div>
         </div>
            <audio id="recorderBeepAudio" src="@/assets/staticAudio/beep-07a.mp3" autostart="false" @ended="beepEnded()" ></audio>
 </div>

    </div>
    </div>
</template>
<script>
// import $ from "jquery";
import Timer from '../reuseable/timerForMock'
import Vue from 'vue';
export default {
    components:{
        'app-Timer':Timer,
    },
    props:{
        weightTime:{
            required:true,
            deafult:.416 // 1-minute divided by seconds
        },
        audioSrc:{
            required:true,
            default:null
        },
        noBeep:{
            default:false,
            required:false
        }
    },
    data(){
        return{
            max:0, // as their are 60 seconds in one minute
            value: 0,
            interval:null,
            audioStop:false,
            recordControll:null,

            slider: {
                lineHeight: 5,
                disabled:true
                // processStyle: {
                // backgroundColor: 'white'
                // }
            },
            src:this.audioSrc,
            wt:this.weightTime,
            recordMessage:'Audio begin in ',
            status:'before',
            player:null
        }
    },
    methods:{
        beepFirst(){
           if(this.$refs!=null)this.$refs.Timer.setTime(0)
           var sound = document.getElementById("recorderBeepAudio");
           sound.playbackRate = 0.5;
            sound.play()
      },
      beepEnded(){
        this.status='after'
        this.started()
      },
      timerEnd(){
          if(this.status=='before'){
              this.beepFirst()
              this.value=0
          }
      },
      audioPlay(){
          
          //console.log('Audio Start')
          var limit=this.max=Math.round(this.player.duration)
          this.$refs.Timer.setTime(Math.round(limit))
          var vm=this
          this.recordMessage='Playing'
           this.interval = setInterval(function () {
               if(vm.value < limit)
               {
                   ////console.log(vm.value)
                   vm.value++
               }
               else{
                   vm.stop()
               }
            }, 1000);
      },
      started(){
          clearInterval(this.interval);
          this.audioStop=false
          this.player = document.getElementById("player");
          this.player.load()
          this.player.play()
          this.$parent.$parent.$parent.$parent.nextOff=true
      },
      stop(){
          //console.log('stop')
          if(!this.player.paused) 
          {
            this.player.pause()
            this.$parent.$parent.$parent.$parent.nextOff=false
          }
          this.$parent.$parent.$parent.$parent.nextOff=false
            this.audioStop=true
            this.recordMessage='Complete'
            clearInterval(this.interval);
            if(this.$parent.audioFinish!=null)this.$parent.audioFinish()
            //$('#nextButton').click()
      },
      reset(){
           this.audioStop=false
           this.$parent.$parent.$parent.$parent.nextOff=false
           this.status='before'
           this.recordMessage='Audio begin in '
            if(this.interval!=null) clearInterval(this.interval);
            Vue.nextTick(()=>{
                this.$refs.Timer.setTime(this.wt)
                this.value=0
            })
            if(this.noBeep){
                var sound = document.getElementById("recorderBeepAudio");
                sound.volume = 0.0;
            }
      },
      async changeAudio(wt,src){
          if(this.recordMessage=='Playing')
          {
              this.recordMessage='Audio begin in '
              this.audioStop=true
            if(!this.player.paused) this.player.pause()
          }
          this.src=src
          this.wt=wt
          this.reset()
          
          
      }
    },
    mounted(){
        ////debugger
       this.reset()
        ////console.log(this.weightTime)

    },
}
</script>
<style scoped>
.invissible
{
opacity: 0!important;
position: absolute!important;
}
#customRecord .ar-content .ar-recorder .ar-recorder__stop svg {
    display: block!important;
}
#customRecord .ar-content .ar-recorder .ar-recorder__stop {
    display: block!important;
    position: relative!important;
    top: 0px !important;
    right: 0px !important;
}
div#timeline1 {
    width: 99%;
    background: #ccc;
    display: block;
    border-radius: 10px;
}

div#seekObj1 {
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 50%;
}

</style>
<style>
.placeInline p, .placeInline div{
display: inline!important;
}
.player .vue-slide-bar-component
{
    padding-top: 20px!important;
padding-bottom: 20px;
}
.player .vue-slide-bar-tooltip::before {
    display: none!important;
}
.player .vue-slide-bar-tooltip
{
position: relative!important;
font-size: 0px!important;
white-space: nowrap!important;
padding: 8px!important;
width: 12px!important;
text-align: center!important;
color: #fff!important;
border-radius: 50%!important;
height: 12px!important;
display: block!important;
}
.player .vue-slide-bar-tooltip-top{
    top: -7px !important;
    left: 0px!important;
}
.player .vue-slide-bar-tooltip-container
{
left: 8px!important;
top: 0px!important;
}
.player .microRecordDiv .vue-slide-bar{
height:5px!important;
}
.extrapadding
{
    padding-left: 10rem!important;
    padding-right: 10rem!important;
}
.player .microRecordDiv
{
    max-width: 300px!important;
    background: #fff!important;
}
</style>
