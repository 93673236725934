<template>
<div class="row">
    <div class="col-12 extrapadding text-center">
        
    <div class="row">
        <div class="col-12 text-center mt-4">
            <div class="fixedWidth microRecordDiv m-2 text-center pl-3 pr-3">
                <h6><strong> Recorded Answer</strong></h6>
                <p class="text-left mt-1" style="font-weight:600;">Current Status:</p>
                <p class="text-left mt-1 mb-2 placeInline" >{{recordMessage}} <app-Timer name="before" ref="Timer" :increment="false"/> <span v-if="status=='before'">seconds</span></p>
                <div class="audio-player">
                    <VueSlideBar v-model="value"
                    class='recorder'
                    :min="0"
                    :max="max"
                    :processStyle="slider.processStyle"
                    :lineHeight="slider.lineHeight"
                    :is-disabled="true" 
                    :draggable="false"
                    :tooltipStyles="{ backgroundColor: 'white', borderColor: 'white' }"
                    />
                <audio-recorder
                                :mic-failed="micCheck"
                                :before-recording="beforeRecording"
                                :after-recording="afterRecording"
                                :pause-recording="pauseRecording"
                                :time="td"
                                :src=null
                                class="invissible"
                                id="customRecord"
                                />
                </div>
            </div>
        </div>
        <!-- :isDisabled="slider.disabled"  :is-disabled="true" :draggable="false" -->
        <audio id="recorderBeepAudio" src="@/assets/staticAudio/beep-07a.mp3" 
            autostart="false" @ended="beepEnded()" >
        </audio>
    </div>

    </div>
</div>
</template>
<script>
import $ from "jquery";
import Timer from '../reuseable/timerForMock'
import Vue from 'vue';
const sdk = require("microsoft-cognitiveservices-speech-sdk");
export default {
    components:{
        'app-Timer':Timer,
    },
    props:{
        totalduration:{
            required:true,
            deafult:.666 // 1-minute divided by seconds
        },
        weightTime:{
            required:true,
            deafult:.416 // 1-minute divided by seconds
        },
         noBeep:{
            default:false,
            required:false
        },
        convert:{
            default:false,
            required:false
        }
    },
    data(){
        return{
            recordSrc:null,
            max:this.totalduration, // as their are 60 seconds in one minute
            value: 0,
            interval:null,
            recordStop:false,
            recordControll:null,
            microsoftTranscript:'',
            slider: {
                lineHeight: 5,
                disabled:true,
                processStyle: {
                backgroundColor: 'grey'
                }
            },
            recordMessage:'Recording begin in ',
            status:'before',
            wt:this.weightTime,
            td:this.totalduration,
            recognizer:null,
        }
    },
    methods:{
        beepFirst(){
           if(this.$refs.Timer!=null)
           {
               this.$refs.Timer.setTime(0)
           var sound = document.getElementById("recorderBeepAudio");
           sound.playbackRate = 0.5;
            sound.play()
           }
      },
      beepEnded(){
        this.status='after'
        this.started()
      },
      checkRecordingPause(){
            if(this.recordMessage!='Complete')
                {
                let myVar =  setInterval(async function() {
                    
                    if(this.microsoftTranscript!==this.microsoftTranscript & this.recordMessage!='Complete')
                    {
                        //console.log('Inside Interval')
                        this.checkRecordingPause()
                    }
                    else{
                        //console.log('Interval stop')
                        clearInterval(myVar);
                        await this.$parent.moveToNextRA()
                        //$('.ar-recorder__stop').click()
                    }
                }, 3000);
            }
        },
    fromCustomMic() {
            //console.log('In Mic') 
            const c_speechConfig = sdk.SpeechConfig.fromSubscription(process.env.VUE_APP_MICRO_API, process.env.VUE_APP_MICRO_SERVICEREGION);
            let c_audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
            //c_speechConfig.setProperty(sdk.PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs, "3000");
            this.c_recognizer = new sdk.SpeechRecognizer(c_speechConfig, c_audioConfig);
            //const phraseList = sdk.PhraseListGrammar.fromRecognizer(this.c_recognizer);
            //phraseList.addPhrase(this.selectedquestion.transcript);
            //console.log('1) Speak into your microphone.');


            this.c_recognizer.startContinuousRecognitionAsync();

            this.c_recognizer.recognizing = (s, e) => {
                if(e!=null)
                {
                    //console.log('2) Speak into your microphone.');
                     //this.microsoftTranscript =e.result.text;
                    //console.log(`RECOGNIZING sub : Text=${e.result.text}`);
                }
            };

            this.c_recognizer.recognized = (s, e) => {
                ////console.log(e.result)
                    if (e.result.reason == 3) {
                        //console.log('3) Speak into your microphone.');
                     this.microsoftTranscript +=e.result.text;
                     this.checkRecordingPause()
                        //console.log(`RECOGNIZED final: Text=${e.result.text}`);
                    }
                    else if (e.result.reason == 0) {
                        //console.log('4) Speak into your microphone.');
                        this.$parent.moveToNextRA()
                        //console.log("NOMATCH: Speech could not be recognized.");
                    }
            };

            this.c_recognizer.canceled = (s, e) => {
                //console.log('5) Speak into your microphone.');
                //console.log(`CANCELED: Reason=${e.reason}`);

                if (e.reason == 0) {
                    //console.log('6) Speak into your microphone.');
                    //console.log(`"CANCELED: ErrorCode=${e.errorCode}`);
                    //console.log(`"CANCELED: ErrorDetails=${e.errorDetails}`);
                    //console.log("CANCELED: Did you update the subscription info?");
                }

                this.c_recognizer.stopContinuousRecognitionAsync();
            };

            this.c_recognizer.sessionStopped = () => {
                ////console.log(s+e)
                //console.log('7) Speak into your microphone.');
                //console.log("\n    Session stopped event.");
                this.c_recognizer.stopContinuousRecognitionAsync();
            };
            
        },
      timerEnd(){
          if(this.status=='before'){
              this.beepFirst()
          }
      },
        beforeRecording(){
          this.microsoftTranscript=''
          //this.$parent.fromMic()
          //this.recordMessage='Press to stop Recording'
          //console.log('before Recording')
          if(this.convert) this.$parent.fromMic()
          this.recordSrc=null
          var limit=this.td
          this.value=0
          ////console.log(limit)
          var vm=this
           this.interval = setInterval(function () {
               if(vm.value < limit)
               {
                   ////console.log(vm.value)
                   vm.value++
               }
               else{
                   $('#nextButton').click()
                   //vm.$parent.moveToNext()
               }
            }, 1000);
        this.recordMessage='Recording'
        //this.fromCustomMic()
        this.$nextTick(function(){
        if(this.$refs.Timer!=null)
        {
            this.$refs.Timer.incrementBas=true
            this.$refs.Timer.setTime(0)
        }
        
        })
          //$('.ar-recorder__stop').addClass('upFront')
      },
      afterRecording(msg){
          //console.log('after recording')
          //this.c_recognizer.stopContinuousRecognitionAsync();
          //this.$parent.recordSrc =
          this.$parent.recordSrc =this.recordSrc=msg.blob
          //console.log(this.$parent.recordSrc)
          this.recordMessage='Complete'
      },
      pauseRecording(){
          $('.ar-recorder__stop').click()
      },
      micCheck(){
      },
      started(){
          clearInterval(this.interval);
          this.recordStop=false
          this.recordSrc=null
          if(this.recordControll == null) this.recordControll=$('.ar-recorder > .ar-icon.ar-icon__lg')
          this.recordControll.click()
      },
      async stop(){
            clearInterval(this.interval);
            $('.ar-recorder__stop').click()
            this.recordStop=true
      },
      async changeTiming(wt,td){
          this.wt=wt
          this.td=td
          this.restart()
      },
      restart(){
           if(this.noBeep){
                var sound = document.getElementById("recorderBeepAudio");
                sound.volume = 0.0;
            }
            this.value=0
           this.recordStop=false
           this.recordMessage='Recording begin in '
           this.status='before'
        if(this.interval!=null) clearInterval(this.interval);
        if(this.wt==0){
            this.beepFirst()
        }
        else{
            Vue.nextTick(()=>{
            this.$refs.Timer.setTime(this.wt)
        })
        }
        
      }
    },
    mounted(){
       this.restart()
        ////console.log(this.weightTime)

    },
}
</script>
<style scoped>
.invissible
{
opacity: 0!important;
position: absolute!important;
}
#customRecord .ar-content .ar-recorder .ar-recorder__stop svg {
    display: block!important;
}
#customRecord .ar-content .ar-recorder .ar-recorder__stop {
    display: block!important;
    position: relative!important;
    top: 0px !important;
    right: 0px !important;
}
div#timeline1 {
    width: 99%;
    background: #ccc;
    display: block;
    border-radius: 10px;
}

div#seekObj1 {
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 50%;
}

</style>
<style>

.vue-slide-bar-component
{
    padding-top: 20px!important;
padding-bottom: 20px;
}
.vue-slide-bar-tooltip::before {
    display: none!important;
}
.vue-slide-bar-tooltip
{
position: relative!important;
font-size: 0px!important;
white-space: nowrap!important;
padding: 8px!important;
width: 12px!important;
text-align: center!important;
color: #fff!important;
border-radius: 50%!important;
height: 12px!important;
display: block!important;
}
.vue-slide-bar-tooltip-top{
    top: -7px !important;
}
.vue-slide-bar-tooltip-container
{
left: 8px!important;
top: 0px!important;
}
.microRecordDiv .vue-slide-bar{
height:5px!important;
}
.extrapadding
{
    padding-left: 10rem!important;
    padding-right: 10rem!important;
}
.fixedWidth.microRecordDiv
{
    max-width: 300px;
    margin: auto!important;
}
</style>
